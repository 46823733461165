import request from '@/utils/request'
export function getSelectshopRequest(params) {
  return request({
    url: '/admin/fj_couponuser/getselectlisttype',
    method: 'get',
    params
  })
}
// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/fj_couponuser/getList',
    method: 'get',
    params
  })
}

 
 
// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/fj_couponuser/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/fj_couponuser/del',
    method: 'get',
    params
  })
}

// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/fj_couponuser/edit',
    method: 'post',
    data
  })
}



// 修改状态
export function setStatesRequest(params) {
    return request({
      url: '/admin/fj_couponuser/setStates',
      method: 'get',
      params
    })
  }

// 修改排序
export function setSortRequest(params) {
    return request({
      url: '/admin/fj_couponuser/setSort',
      method: 'get',
      params
    })
  }