<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>

            <el-breadcrumb-item>优惠券领取列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>

      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">

      <el-form-item>
        <el-input v-model="name" placeholder="请输入优惠券"></el-input>
      </el-form-item>

      <el-form-item label="状态">
        <el-radio-group v-model="states">
          <el-radio :label=-1>全部</el-radio>
          <el-radio :label=0>未使用</el-radio>
          <el-radio :label=1>已使用</el-radio>
          <el-radio :label=2>已过期</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="adminList" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center" width="95"></el-table-column>

        <el-table-column prop="fj_couponNo" label="优惠券编号"></el-table-column>

        <el-table-column prop="fj_couponuserno" label="领取编号"></el-table-column>
        <el-table-column prop="userid" label="领取会员Id"></el-table-column>
        <el-table-column prop="name" label="优惠券标题"></el-table-column>

        <el-table-column prop="money" label="价值"></el-table-column>

        <el-table-column prop="fullmoney" label="使用条件">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.fullmoney==0">无限制使用</el-tag>
            <el-tag v-if="scope.row.fullmoney>0">满{{scope.row.fullmoney}}元使用</el-tag>
          </template>

        </el-table-column>

        <el-table-column prop="endtime" label="有效期"></el-table-column>

        <el-table-column prop="addtime" label="领取日期"></el-table-column>
        <el-table-column prop="states" label="使用状态">
          <template slot-scope="scope"> {{['未使用','已使用','已过期'][scope.row.states]}} </template>
        </el-table-column>
        <el-table-column prop="usetime" label="使用日期"></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex" :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getListRequest, setStatesRequest, delRequest, setSortRequest } from '@/api/fj_couponuser'

export default {
  name: 'advlist',
  data() {
    return {
      name: '',
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      shopdata: [],
      productdata: [],
      states: -1,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getListRequest({
        fj_couponNo: this.name,
        states: this.states,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        let data = res.data
        this.adminList = data.data
        this.pageTotal = data.count
      })
    },

    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder,
      }).then((res) => {})
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states,
      }).then((res) => {
        row.states = states
      })
    },
    toCreate() {
      this.$router.push({
        path: '/fj_coupon/edit',
        query: {
          id: 0,
        },
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/fj_coupon/edit',
        query: {
          id: id,
        },
      })
    },

    onDelete(id) {
      this.$confirm('此操作将删除改行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        delRequest({ Id: id }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: 'success',
              message: `成功`,
            })
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
</style>
